import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useAuth0 } from '@auth0/auth0-react';
import { SectionBlocks } from '../../components/SectionBlocks';
import { Layout } from '../../components/layout/Layout';
import { useCloudinary } from '../../hooks/Cloudinary/useCloudinary';
import routeConfig from '../../config/routeConfig';
import {
  ProductPageAppPropertiesType,
  SectionBlockType,
  TopicPageAppPropertiesType,
  TopicPageAppType,
} from '../../../../types/CategoryAppType';
import { SiteStructureType } from '../../../../types/siteStructureType';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';
import { useWindowLocation } from '../../hooks/Window/useWindowLocation';
import { SectionContainer } from '../DynamicApp/DynamicApp';
import { createNorwegianDateString } from '../../utils/createNorwegianDateString';
import ErrorBoundary from '../../components/error-component/ErrorBoundary';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { LoaderContent } from '../LoaderPage';
import { Hero } from '../../components/SectionBlocks/blocks/ThemeHeroBlock/Hero';

export const TopicPageApp = ({
  data,
  appData,
  asArticleData,
}: {
  data?: SiteStructureType;
  appData?: TopicPageAppType;
  asArticleData?: TopicPageAppPropertiesType | ProductPageAppPropertiesType;
}) => {
  const contentUrl = useWindowLocation();
  const topicPageData = appData?.properties || asArticleData;
  const cld = useCloudinary();
  const generatedCrumbs = useBreadcrumbs(routeConfig);
  const auth = useAuth0();
  const themeType = data?.theme;

  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);

  const shouldLogin = topicPageData.requireLogin && !auth.isAuthenticated;
  const loginSections = shouldLogin ? topicPageData.loginSections : [];

  const hasParallaxEffect = (sectionData?: SectionBlockType[]) => {
    if (sectionData) {
      return sectionData.some((section) => section.type === 'scrollableTextBlocksWithBackgroundImages');
    }
    return false;
  };

  const isLoginHeroWithTitleAndWideImage =
    topicPageData && topicPageData?.loginHero?.heroVariant === 'heroWithTitleAndWideImage';
  const isHeroWithTitleAndWideImage = topicPageData && topicPageData?.hero?.heroVariant === 'heroWithTitleAndWideImage';

  const categoryImage =
    (data?.image &&
      data?.image.publicId &&
      cld
        .image(data?.image.publicId)
        .resize(fill().width(1200).height(630))
        .quality('auto:best')
        .format('auto')
        .toURL()) ||
    undefined;

  if (topicPageData.requireLogin && (!isClient || auth.isLoading)) {
    return (
      <Main>
        <LoaderContent />
        <CenterContainer />
      </Main>
    );
  }

  return (
    <Layout
      title={data?.name || asArticleData?.seoConfig?.title || data?.seoConfig?.title || asArticleData?.hero?.header}
      description={data?.ingress || data?.seoConfig?.introduction || 'NAF temaside'}
      seoDescription={
        asArticleData?.seoConfig?.introduction || data?.seoConfig?.introduction || data?.ingress || 'NAF temaside'
      }
      imgUrl={categoryImage || ''}
      seoTitle={asArticleData?.seoConfig?.title || data?.seoConfig?.title || data?.name}
      isHiddenFromSearch={asArticleData?.seoConfig?.isHiddenFromSearch || data?.seoConfig?.isHiddenFromSearch}
      gtmArgs={{ page_type: 'TopicPageApp', pageCategory: data?.slug || '', contentId: data?.name || '' }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.CategoryApp }}
    >
      {shouldLogin ? (
        <>
          {isLoginHeroWithTitleAndWideImage && (
            <StyledHeroBlock
              block={topicPageData.loginHero}
              breadCrumbs={!topicPageData.hideBreadcrumbs ? generatedCrumbs : null}
            />
          )}
          <Main>
            <ErrorBoundary>
              {!isLoginHeroWithTitleAndWideImage && (
                <StyledHeroBlock
                  block={topicPageData.loginHero}
                  breadCrumbs={!topicPageData.hideBreadcrumbs ? generatedCrumbs : null}
                />
              )}
              <LoginSectionContainer>
                <SectionBlocks content={loginSections} activateContextThemeSwitch={hasParallaxEffect(loginSections)} />
              </LoginSectionContainer>
              <CenterContainer />
            </ErrorBoundary>
          </Main>
        </>
      ) : (
        <>
          {isHeroWithTitleAndWideImage ? (
            <StyledHeroBlock block={topicPageData.hero} breadCrumbs={generatedCrumbs} themeType={themeType} />
          ) : null}
          {topicPageData && (
            <Main>
              {!isHeroWithTitleAndWideImage && (
                <StyledHeroBlock
                  block={topicPageData.hero}
                  breadCrumbs={!topicPageData.hideBreadcrumbs ? generatedCrumbs : null}
                />
              )}
              <ErrorBoundary>
                {topicPageData.sections && (
                  <StyledSectionContainer>
                    <SectionBlocks
                      content={topicPageData.sections}
                      activateContextThemeSwitch={hasParallaxEffect(topicPageData.sections)}
                    />
                  </StyledSectionContainer>
                )}
                {topicPageData.metaDisplay &&
                  (!topicPageData?.metaDisplay?.hidePublicationDate || !topicPageData?.metaDisplay?.hideEditor) && (
                    <CenterContainer>
                      {topicPageData?.meta?.publishAt &&
                        !topicPageData.metaDisplay?.hidePublicationDate &&
                        asArticleData && <div>{createNorwegianDateString(new Date(topicPageData.meta.publishAt))}</div>}
                      {topicPageData?.meta?.editor && !topicPageData?.metaDisplay?.hideEditor && asArticleData && (
                        <div>
                          {topicPageData.meta.editor.name},{' '}
                          <BylineRole>{topicPageData.meta.editor.position}</BylineRole>
                        </div>
                      )}
                    </CenterContainer>
                  )}
                {topicPageData.metaDisplay && !topicPageData.metaDisplay?.hideForm && (
                  <CenterContainer>
                    <FeedbackSurvey contentUrl={contentUrl} />
                  </CenterContainer>
                )}
              </ErrorBoundary>
            </Main>
          )}
        </>
      )}
    </Layout>
  );
};

const StyledHeroBlock = styled(Hero)`
  padding-top: ${spacing.space48};
`;

const Main = styled.main`
  padding-top: ${spacing.space48};
`;

const BylineRole = styled.span`
  font-style: italic;
`;

const StyledSectionContainer = styled(SectionContainer)`
  padding-top: 0;
  row-gap: ${spacing.space48};

  // Setting spacing here for section blocks to avoid having spacing on the blocks themselves interfere with grip-gap in other pages
  .TextAndImageBlock,
  .ImageLinksBlock,
  .NavigationLinksBlock,
  .InputWithImageLinksBlock {
    margin-bottom: ${spacing.space80};

    @media (max-width: ${breakpoints.s}) {
      margin-bottom: ${spacing.space56};
    }
  }

  // Centering text from TextBlock only on themepage
  .TextBlock {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: ${spacing.space24};

    .TextBlockItem {
      grid-column: auto / span 6;
      grid-column-start: 4;
      @media (max-width: ${breakpoints.s}) {
        grid-column-start: 1;
        grid-column: auto / span 12;
      }
    }
  }
`;

const LoginSectionContainer = styled(StyledSectionContainer)`
  margin-top: ${spacing.space120};

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space48};
  }
`;

const CenterContainer = styled.div`
  padding: 0 ${spacing.space48};
  margin: ${spacing.space48} auto;
  max-width: ${breakpoints.xl};
  box-sizing: border-box;
  @media (max-width: ${breakpoints.s}) {
    padding: 0 ${spacing.space24};
  }
`;
